import { QueryHookOptions, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useShowErrorsPopup } from '@/hooks'
import {
  GetProductVariantsArgs,
  Query,
  QueryGetProductArgs,
} from '@/schemaTypes'

const GET_PRODUCT = gql`
  query getProduct(
    $productId: ObjectId!
    $variantsArgs: GetProductVariantsArgs
  ) {
    getProduct(productId: $productId) {
      _id
      categoryId
      isCrawled
      category {
        algoliaCategorySlug
        parentCategories {
          name
        }
      }
      isPublished
      name
      manufacturer
      imageMode
      image(size: "original") {
        id
        url
      }
      overviewImage(size: "original") {
        id
        url
      }
      availableProperties {
        ... on ProductAvailableTextProperty {
          propertyDefinitionId
          propertyDefinition {
            _id
            type
            impactsLook
            sortPriority
            description {
              allTranslations {
                text
                languageCode
              }
            }
            name {
              allTranslations {
                text
                languageCode
              }
            }
            key
            valueFormatting {
              suffix
            }
          }
          values {
            id
            translation {
              allTranslations {
                text
                languageCode
              }
            }
          }
        }

        ... on ProductAvailableNumberProperty {
          propertyDefinitionId
          propertyDefinition {
            _id
            type
            impactsLook
            sortPriority
            description {
              allTranslations {
                text
                languageCode
              }
            }
            name {
              allTranslations {
                text
                languageCode
              }
            }
            key
            valueFormatting {
              suffix
            }
          }
          values {
            numberValue
          }
        }

        ... on ProductAvailableBooleanProperty {
          propertyDefinitionId
          propertyDefinition {
            _id
            type
            impactsLook
            sortPriority
            description {
              allTranslations {
                text
                languageCode
              }
            }
            name {
              allTranslations {
                text
                languageCode
              }
            }
            key
            valueFormatting {
              suffix
            }
          }
        }
      }
      variants(args: $variantsArgs) {
        id
        enabled
        releasedAt
        isPriceValid
        image(size: "original") {
          id
          url
        }
        name
        propertiesValues {
          ... on ProductVariantPropertyTextValue {
            propertyDefinitionId
            propertyDefinition {
              _id
              type
              impactsLook
              sortPriority
              description {
                allTranslations {
                  text
                  languageCode
                }
              }
              name {
                allTranslations {
                  text
                  languageCode
                }
              }
              key
              valueFormatting {
                suffix
              }
            }
            value {
              id
              translation {
                allTranslations {
                  text
                  languageCode
                }
              }
            }
          }
          ... on ProductVariantPropertyNumberValue {
            propertyDefinitionId
            propertyDefinition {
              _id
              type
              impactsLook
              sortPriority
              description {
                allTranslations {
                  text
                  languageCode
                }
              }
              name {
                allTranslations {
                  text
                  languageCode
                }
              }
              key
              valueFormatting {
                suffix
              }
            }
            value {
              numberValue
            }
          }
          ... on ProductVariantPropertyBooleanValue {
            propertyDefinitionId
            propertyDefinition {
              _id
              type
              impactsLook
              sortPriority
              description {
                allTranslations {
                  text
                  languageCode
                }
              }
              name {
                allTranslations {
                  text
                  languageCode
                }
              }
              key
              valueFormatting {
                suffix
              }
            }
            value {
              booleanValue
            }
          }
        }
      }
      price {
        ... on ProductPriceCrawledMode {
          mode
          variants {
            variantId
            price
            createdAt
            updatedAt
            adjustment {
              value
              type
              updatedAt
            }
          }
        }
        ... on ProductPriceManualVariantMode {
          mode
          variants {
            variantId
            price
            updatedAt
          }
        }
        ... on ProductPriceManualPropertyMode {
          mode
          basePrice
          properties {
            propertyDefinitionId
            propertyValue
            adjustment {
              value
              type
            }
          }
        }
        ... on ProductPriceManualPreciousMetalMode {
          mode
          material
          isInvestmentGold
          variants {
            variantId
            price
            materialMetric {
              alloy
              weight
            }
          }
        }
      }
    }
  }
`

type TData = { getProduct: Query['getProduct'] }
type TVariables = QueryGetProductArgs & {
  variantsArgs?: GetProductVariantsArgs
}

export function useGetProduct(opts: QueryHookOptions<TData, TVariables> = {}) {
  const queryResult = useQuery<TData, TVariables>(GET_PRODUCT, {
    fetchPolicy: 'network-only',
    ...opts,
  })

  useShowErrorsPopup(queryResult.error)

  return { queryResult, product: queryResult.data?.getProduct }
}
